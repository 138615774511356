<template>
  <div id="radar"></div>
</template>

<script>
import * as echarts from "echarts/core";
export default {
  name: "radar",
  props: {
    config: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      indicator: [
        { name: "易用性", max: "100" },
        { name: "功能", max: "100" },
        { name: "拍照", max: "100" },
        { name: "跑分", max: "100" },
        { name: "续航", max: "100" },
      ],
      scoreList: [
        {
          name: "华为手机",
          value: [80, 90, 80, 82, 90],
        },
      ],
    };
  },

  methods: {
    handleOk() {},
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      this.$nextTick(() => {
        console.log(document.getElementById("radar"));
        let myChart = echarts.init(document.getElementById("radar"));
        console.log(myChart);

        let option = {
          tooltip: {
            trigger: "axis",
          },
          //配置维度的最大值
          radar: {
            name: {
              show: true,
              color: "red",
            },
            //   雷达图的指示器，用来指定雷达图中的多个变量（维度）
            indicator: this.indicator,
            // shape: "circle", //对雷达图形设置成一个圆形,可选 circle:圆形,polygon:多角形(默认)
          },
          series: [
            {
              type: "radar",
              label: {
                // show: true, //显示数值
              },
              // tooltip: {
              //   trigger: "item",
              // },
              areaStyle: {}, //每个雷达图形成一个阴影的面积
              data: this.scoreList,
            },
          ],
        };
        // 绘制图表
        myChart.setOption(option);
      });
    },
  },

  mounted() {
    console.log(this.config);
    this.indicator = this.config.indicator;
    this.scoreList = this.config.scoreList;
    console.log("adfsfasdfasdfasdfasdf");
    this.drawLine();
  },
};
</script>

<style lang="scss" scoped>
#radar {
  width: 400px;
  height: 220px;
}
</style>
