export const SampleRule = {
    // 联系人
    contacts: [
      { required: true, message: "请输入收件人姓名", trigger: "blur" },
      { max: 16, message: "收件人姓名长度不能超过16位", trigger: "change" },
    ],
    // 联系方式
    phone: [
      { required: true, message: "请输入手机号", trigger: "blur" },
      { max: 32, message: "手机号长度不能超过32位", trigger: "change" },
    ],
    // 邮寄地址
    mailAddress: [
      { required: true, message: "请输入邮寄地址", trigger: "blur" },
      { max: 128, message: "邮寄地址长度不能超过128位", trigger: "change" },
    ],
  };