<template>
  <div>
    <!-- <Breadcrumb /> -->
    <InquiryModal
      :inquiryModalVisible="inquiryModalVisible"
      v-if="inquiryModalVisible"
      :config="configInquiry"
    />
    <SampleModal
      v-if="sampleVisible"
      :sampleVisible="sampleVisible"
      :config="configInquiry"
    />
    <OrderModal
      :orderModalVisible="orderModalVisible"
      :product="products"
      :datas="modelData"
    />
    <div class="section">
      <div class="section-content detail-section">
        <a-skeleton
          :loading="loading"
          active
          :avatar="{ size: 400, shape: 'square' }"
          :paragraph="{ rows: 10 }"
        >
          <div class="detail-section">
            <div class="glance-container">
              <div class="big-img">
                <a-icon
                  class="video-icon"
                  type="play-circle"
                  v-if="
                    products.spuVideos && products.spuVideos[0] && videoIcon
                  "
                  @click="handleVideo"
                />
                <div class="video-container" v-if="videoVisiable">
                  <!-- 视频遮罩层 -->
                  <div class="overlay-video"></div>
                  <a-icon
                    class="video-icon-close"
                    type="close"
                    @click="handleCloseVideo"
                  />
                  <video autoplay controls :src="products.spuVideos[0]"></video>
                </div>
                <PicZoom :url="bigUrl" :scale="3" />
              </div>

              <div class="small-img-container">
                <Swiper
                  class="swiper-container"
                  ref="mySwiper"
                  :options="swiperOptions"
                >
                  <SwiperSlide
                    v-for="(item, index) in products.spuImags"
                    :key="index"
                  >
                    <div class="goods-list">
                      <img
                        class="imgStyle"
                        @mouseenter="handleMouseenter(item)"
                        :src="item"
                        alt=""
                      />
                    </div>
                  </SwiperSlide>
                </Swiper>
                <div
                  class="swiper-button swiper-button-prev"
                  slot="button-prev"
                  @click.prevent="next"
                >
                  <a-icon type="left" class="icon_bg" />
                </div>
                <div
                  class="swiper-button swiper-button-next"
                  slot="button-next"
                  @click.prevent="prev"
                >
                  <a-icon type="right" class="icon_bg" />
                </div>
              </div>
            </div>

            <div class="info-container">
              <div class="radar">
                <div class="left-wrapper">
                  <div class="name-container">
                    <div class="product-name zh-name">
                      {{ products.goodsNameCn }}
                    </div>
                    <div class="product-name en-name">
                      {{ products.goodsNameEn }}
                    </div>
                  </div>
                  <div class="num-container">
                    <div class="num-item">
                      <div class="num-value" style="width: 100px">
                        {{ products.createTime }}
                      </div>
                      <div class="num-label">上架时间</div>
                    </div>
                    <div class="divider-vertical"></div>
                    <!-- v-if="!(!products.inventory&&products.customized) -->
                    <div class="num-item" v-if="!(products.inventory&&!products.customized)">
                      <div class="num-value">
                        {{ products.makeNumber }}
                      </div>
                      <div class="num-label">总成交量</div>
                    </div>
                    <div class="divider-vertical" v-if="!(products.inventory&&!products.customized)"></div>
                    <div class="num-item">
                      <div class="num-value">
                        {{ products.inquiryNumber }}
                      </div>
                      <div class="num-label">询价次数</div>
                    </div>
                    <div class="divider-vertical" v-if="!(products.inventory&&!products.customized)"></div>
                    <div class="num-item" v-if="!(products.inventory&&!products.customized)">
                      <div class="num-value">
                        {{ products.minOrder }}
                      </div>
                      <div class="num-label">最小起订量</div>
                    </div>
                    <div class="divider-vertical" v-if="!(!products.inventory&&products.customized)"></div>
                    <div class="num-item" v-if="!(!products.inventory&&products.customized)">
                      <div class="num-value">
                        {{ products.inventoryNumber}}
                      </div>
                      <div class="num-label">现有库存</div>
                    </div>
                    <!-- {{ products.inventoryList }} -->
                    <div
                      class="divider-vertical"
                      v-if="!(!products.inventory&&products.customized)"
                    ></div>
                    <div
                      class="num-item"
                      v-if="!(!products.inventory&&products.customized)"
                    >
                      <div class="num-value">
                        {{ products.minInventory }}
                      </div>
                      <div class="num-label">库存起批量</div>
                    </div>
                  </div>
                  <!-- 可售地区  商品现有认证 应该为数组-->
                  <div class="flexbox"></div>
                  <div class="desc-item">
                    <div class="desc-label">可售地区</div>
                    <div class="desc-value">
                      <div
                        class="rz"
                        v-for="(item, index) in products.availableAreaUse"
                        :key="index"
                      >
                        <a-icon
                          type="fire"
                          theme="filled"
                          style="color: #f94639"
                        />
                        <span>{{ item }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="desc-item">
                    <div class="desc-label">商品现有认证</div>
                    <div class="desc-value">
                      <div
                        class="rz"
                        v-for="(item, index) in products.productQualification"
                        :key="'pro' + index"
                      >
                        <a-icon
                          type="safety-certificate"
                          theme="twoTone"
                          two-tone-color="#FE8432"
                        />
                        <span>{{ item }}</span>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="products.specialTag&&products.specialTag.length > 0 && !(products.inventory&&!products.customized)"
                    class="inventory"
                  >
                    <div style="margin-right: 47px; color: #999999" class="inventory-tag">
                      特殊标签
                    </div>
                    <div
                      v-for="(item, index) in products.specialTag"
                      :key="index"
                      class="inventory-tag-item"
                    >
                      {{ item }}
                    </div>
                  </div>
                  <div
                    v-if="products.inventoryList && products.inventoryList.length > 0"
                    class="inventory"
                  >
                    <div style="margin-right: 19px; color: #999999" class="inventory-tag">
                      库存处理标签
                    </div>
                    <div
                      v-for="(item, index) in products.inventoryTags"
                      :key="index"
                      class="inventory-tag-item"
                    >
                      {{ item }}
                    </div>
                  </div>
                  <div
                    style="margin-top: 10px; display: flex"
                    v-if="products.inventoryList.length > 0"
                  >
                    <div style="color: #999999; margin-right: 45px">
                      现货地址
                    </div>
                    <div style="font-size: 14px">{{ products.address }}</div>
                  </div>
                  <div
                    style="margin-top: 10px; display: flex"
                    v-if="products.inventoryList.length > 0"
                  >
                    <div style="color: #999999; margin-right: 45px">
                      现货规格
                    </div>
                    <div style="font-size: 14px">
                      {{ products.spotSpecification }}
                    </div>
                  </div>
                  <!-- 商品是否检测 -->
                  <div class="desc-item" v-if="products.isDetect">
                    <a-icon
                      class="icon-style"
                      type="check-circle"
                      theme="twoTone"
                      two-tone-color="#52c41a"
                    />
                    该产品已通过中科院0距离选品质量检测
                  </div>
                </div>

                <div class="right-wrapper">
                  <div v-if="isEvaluation">
                    <div class="starN">
                      <span>工厂星级评价：</span>
                      <a-rate v-model="starValue" disabled />
                    </div>
                    <Radar :config="configFrom" />
                  </div>
                  <a-empty v-else description="工厂暂无评分" />
                </div>
              </div>

              <div class="divider"></div>

              <div class="flexbox">
                <div class="desc-item">
                  <div class="desc-label">商品类别：</div>
                  <div class="desc-value">{{ products.goodsCategoryName }}</div>
                </div>
                <div class="desc-item">
                  <div class="desc-label">品牌：</div>
                  <div class="desc-value">{{ products.brandName }}</div>
                </div>
                <div class="desc-item">
                  <div class="desc-label">质保期：</div>
                  <div class="desc-value">{{ products.warranty }}</div>
                </div>
                <div class="desc-item">
                  <div class="desc-label">生产周期：</div>
                  <div class="desc-value">{{ products.productionCycle }}</div>
                </div>
              </div>

              <div class="desc-item">
                <div class="desc-label">包装清单：</div>
                <div class="desc-value">{{ products.packingList }}</div>
              </div>

              <div class="desc-item">
                <div class="desc-label">商品卖点：</div>
                <div class="desc-value">
                  {{ products.commoditySellingPoint }}
                </div>
              </div>

              <div class="desc-item">
                <div class="desc-label">商品规格：</div>
                <div class="desc-value">
                  <table border="1">
                    <thead>
                      <tr>
                        <th>
                          商品毛重 <br />
                          (kg/件)
                        </th>
                        <th>
                          商品净重 <br />
                          (kg/件)
                        </th>
                        <th>
                          商品尺寸cm <br />
                          (长*宽*高)
                        </th>
                        <th>
                          包装尺寸cm <br />
                          (长*宽*高)
                        </th>
                        <th>
                          外包装尺寸cm <br />
                          (长*宽*高)
                        </th>
                        <th>外包装箱规 <br />(件)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{ products.grossWeight }}</td>
                        <td>{{ products.netWeight }}</td>
                        <td>{{ products.goodsSize }}</td>
                        <td>{{ products.packingSize }}</td>
                        <td>{{ products.outerPackingSize }}</td>
                        <td>{{ products.casesGauge }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </a-skeleton>
      </div>
    </div>
    <div class="section">
      <div class="section-content">
        <a-tabs type="card" v-model="selectPane" @change="changeType" >
          <a-tab-pane key="1" tab="SKU" v-if="products.skuvos.length>0">
            <a-table
              :columns="columns"
              :data-source="products.skuvos"
              :loading="loading"
            >
              <a slot="skuImages" slot-scope="text">
                <img class="simg" :src="text" />
              </a>
              <a slot="onPrice" slot-scope="text">
                <span class="red">{{ text }}</span>
              </a>
              <a slot="goodsNum" slot-scope="text, record">
                <a-input-number
                  :value="text"
                  :min="mins"
                  :formatter="limitNumber"
                  :parser="limitNumber"
                  placeholder="请输入"
                  @change="(e) => handleChange(e, record.key, 'goodsNum')"
                />
              </a>
              <a slot="timeDelivery" slot-scope="text, record">
                <a-date-picker
                  format="YYYY-MM-DD"
                  :disabled-date="disabledDate"
                  :disabled-time="disabledDateTime"
                  placeholder="请输入交货时间"
                  @change="(e) => handleChange(e, record.key, 'timeDelivery')"
                />
              </a>
              <a slot="placeDelivery" slot-scope="text, record">
                <a-input
                  :value="text"
                  placeholder="请输入交货地址"
                  v-decorator="[
                    'placeDelivery',
                    {
                      rules: [
                        { required: true, message: '请输入交货地址!' },
                        {
                          min: 0,
                          max: 10,
                          message: '交货地址最长为64位',
                          trigger: 'blur',
                        },
                      ],
                    },
                  ]"
                  @change="
                    (e) =>
                      handleChange(e.target.value, record.key, 'placeDelivery')
                  "
                />
              </a>
              <a slot="action" slot-scope="text, record">
                <div>
                  <a-button
                    class="btns"
                    :disabled="isGrounding"
                    @click="askModal(record.skuId, record)"
                    >询价</a-button
                  >
                  <a-button
                    class="btns"
                    :disabled="isGrounding"
                    @click="sampleApply(record)"
                    >样品申请</a-button
                  >
                  <a-button
                    class="btns"
                    type="primary"
                    :disabled="isGrounding"
                    @click="openModal(record)"
                    v-if="products.state === 0"
                  >
                    下单
                  </a-button>
                </div>
              </a>
            </a-table>
          </a-tab-pane>
          <a-tab-pane
            key="3"
            tab="库存处理"
            v-if="products.inventoryList.length > 0"
          >
            <a-table
              :columns="inventoryColumns"
              :data-source="products.inventoryList"
              :loading="loading"
            >
              <a slot="inventoryImages" slot-scope="text">
                <img class="simg" :src="text" />
              </a>
              <a slot="handlePrice" slot-scope="text">
                <span class="red">{{ text }}</span>
              </a>
              <a slot="action" slot-scope="text, record">
                <div>
                  <a-button
                    class="btns"
                    :disabled="isGrounding"
                    @click="askModal(record.inventoryId, record)"
                    >询价</a-button
                  >
                  <a-button
                    class="btns"
                    :disabled="isGrounding"
                    @click="sampleApply(record)"
                    >样品申请</a-button
                  >
                </div>
              </a>
            </a-table>
          </a-tab-pane>
          <a-tab-pane key="2" tab="商品详情">
            <div class="goods-video-container" v-if="products.detailVideos[0]">
              <video
                class="goods-video"
                controls
                v-if="products.detailVideos[0]"
                :src="products.detailVideos[0]"
              ></video>
            </div>
            <div class="img-container">
              <img
                v-for="(item, i) in products.detailImages"
                :src="item"
                :key="i"
              />
            </div>
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
  </div>
</template>
<script>
// import Breadcrumb from "../components/breadcrumb.vue";
import InquiryModal from "../components/inquiryModal.vue";
import SampleModal from "../components/sampleModal.vue";
import OrderModal from "../components/orderModal.vue";
// 放大镜插件
import PicZoom from "vue-piczoom";
// 轮播图
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { CategoryAPI } from "@/api/Category.js";
import { MallAPI } from "@/api/mall.js";
import moment from "moment";
import { createNamespacedHelpers } from "vuex";
const { mapState } = createNamespacedHelpers("Public");
import Routerpush from "@/mixins/Routerpush";
import Radar from "../components/radar.vue";
import LocalCache from "@/utils/cache.js";
export default {
  mixins: [Routerpush],
  data() {
    return {
      moment,
      columns: [
        {
          title: "图片",
          dataIndex: "skuImages",
          key: "skuImages",
          scopedSlots: { customRender: "skuImages" },
        },
        {
          title: "型号",
          dataIndex: "skuType",
          key: "skuType",
          scopedSlots: { customRender: "skuType" },
        },
        {
          title: "颜色",
          dataIndex: "skuColor",
          key: "skuColor",
          scopedSlots: { customRender: "skuColor" },
        },
        {
          title: "基础价",
          dataIndex: "onPrice",
          key: "onPrice",
          scopedSlots: { customRender: "onPrice" },
        },
        {
          title: "订购数量",
          dataIndex: "goodsNum",
          key: "goodsNum",
          scopedSlots: { customRender: "goodsNum" },
        },
        {
          title: "期望交货时间",
          dataIndex: "timeDelivery",
          key: "timeDelivery",
          scopedSlots: { customRender: "timeDelivery" },
        },
        {
          title: "期望交货地址",
          dataIndex: "placeDelivery",
          key: "placeDelivery",
          scopedSlots: { customRender: "placeDelivery" },
        },
        {
          title: "操作",
          dataIndex: "action",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
      inventoryColumns: [
        {
          title: "图片",
          dataIndex: "inventoryImages",
          key: "inventoryImages",
          scopedSlots: { customRender: "inventoryImages" },
        },
        {
          title: "型号",
          dataIndex: "inventoryType",
          key: "inventoryType",
          scopedSlots: { customRender: "inventoryType" },
          width: 150,
        },
        {
          title: "颜色",
          dataIndex: "inventoryColor",
          key: "inventoryColor",
          scopedSlots: { customRender: "inventoryColor" },
        },
        {
          title: "现有库存",
          dataIndex: "inventoryNum",
          key: "inventoryNum",
          scopedSlots: { customRender: "inventoryNum" },
        },
        {
          title: "处理价（元）",
          dataIndex: "handlePrice",
          key: "handlePrice",
          scopedSlots: { customRender: "handlePrice" },
        },
        {
          title: "操作",
          dataIndex: "action",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
      data: [],
      bigUrl: "",
      inquiryModalVisible: false, // 询价弹框显示
      sampleVisible: false, // 样品申请弹框显示
      orderModalVisible: false, // 下单弹框显示
      banner: [],
      swiperOptions: {
        loop: false,
        speed: 200,
        slidesPerView: 5, //显示个数
        spaceBetween: 1.5,
        autoplay: {
          delay: 1000,
        },
        navigation: {
          nextEl: ".swiper-button-prev",
          prevEl: ".swiper-button-next",
        },
      },
      products: {},
      modelData: [],
      mins: 1,
      loading: true,
      starValue: null, // 星级评定数据
      configFrom: {
        indicator: null,
        scoreList: null,
      },
      configInquiry: {
        goodsNameCn: null,
        skuType: null,
        goodsId: null,
      },
      isEvaluation: false,
      videoVisiable: false, // 控制SPU视频显示
      videoIcon: true, // 视频播放按钮显示
      isGrounding: false, // 记录该商品是否上架（控制询价、样品申请、下单是否禁用）
      selectPane:'0',//默认tab页
    };
  },
  components: {
    // Breadcrumb,
    InquiryModal,
    SampleModal,
    OrderModal,
    PicZoom,
    Swiper,
    SwiperSlide,
    Radar,
  },

  computed: {
    ...mapState(["Token"]),
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },

  created() {
    const detail = this.$route.query;
    this.GetDetail(detail);
      if(this.$route.query.name==='5'){
      this.selectPane='3'
    }else{
      this.selectPane='1'
    }
  },

  methods: {
    // 询价
    askModal(id, record) {
      console.log("询价接口", record);
      //走询价接口
      MallAPI.goodsInquiry(id).then(() => {});
      this.inquiryModalVisible = true;
      this.configInquiry.skuType = record.skuType;
    },

    // 样品申请
    sampleApply(record) {
      this.sampleVisible = true;
      this.configInquiry.skuType = record.skuType;
    },

    // 下单
    openModal(row) {
      if (
        row.goodsNum <= 0 ||
        !row.placeDelivery ||
        moment(row.timeDelivery).format() === "Invalid date"
      ) {
        this.$message.error("订购数量/交货时间/交货地址为必填");
        return;
      }
      console.log(row.placeDelivery.length);
      if (row.placeDelivery.length > 64) {
        this.$message.error("交货地址长度最多为64位");
        return;
      }
      console.log(LocalCache.getCache("token"));
      if (LocalCache.getCache("token") !== undefined) {
        this.ckeckStatus().then(() => {
          this.modelData = [row];
          this.orderModalVisible = true;
        });
      } else {
        let that = this;
        this.$confirm({
          title: "提示",
          content: "身份已失效，请进行以下操作。",
          okText: "去登录",
          cancelText: "取 消",
          onOk() {
            that.loginRoute();
          },
          onCancel() {},
        });
      }
    },

    ckeckStatus() {
      let that = this;
      return new Promise((resolve) => {
        MallAPI.checkStatus().then((res) => {
          if (res.data) {
            resolve();
          } else {
            this.$confirm({
              title: "提示",
              content: "需要进行企业认证才可以下单询问。",
              okText: "去认证",
              cancelText: "取 消",
              onOk() {
                that.CheckRoute();
              },
              onCancel() {},
            });
          }
        });
      });
    },

    closeModal() {
      this.inquiryModalVisible = false;
      this.orderModalVisible = false;
      this.sampleVisible = false;
    },
    changeType(activeKey){
      console.log(111,this.selectPane);
     console.log('3'===activeKey);
    },
    //swiper指针滑动
    handleMouseenter(url) {
      this.bigUrl = url;
    },

    // SPU有视频的话，处理视频打开与关闭
    handleVideo() {
      this.videoVisiable = true;
      this.videoIcon = false;
    },
    // 关闭视频
    handleCloseVideo() {
      this.videoVisiable = false;
      this.videoIcon = true;
    },

    //上一张
    prev() {
      this.swiper.slideNext();
    },
    //下一张
    next() {
      this.swiper.slidePrev();
    },

    //获取详情
    GetDetail(ids) {
      console.log(localStorage.getItem("token"));
      CategoryAPI.GoodDetail(ids).then((res) => {
        console.log(res.data);
        this.evaluationStar(ids);
        if (res.success) {
          this.isGrounding = res.data.state === 0 ? false : true;
          if (this.isGrounding) {
            this.$message.error("该商品已下架");
          }
          // 询价时在内容前面拼接上商品中文名称
          this.configInquiry.goodsNameCn = res.data.goodsNameCn;
          this.configInquiry.goodsId = res.data.goodsId;
          this.products = res.data;
          this.products.productQualification = this.products
            .productQualification
            ? this.products.productQualification.split(",")
            : [];
            this.products.specialTag = this.products.specialTag
            ? this.products.specialTag.split("/")
            : [];
          this.products.inventoryTags = this.products.inventoryTags
            ? this.products.inventoryTags.split("/")
            : [];
          this.products.skuvos = this.products.skuvos.map((i, index) => {
            i.key = index;
            i.goodsNum = null;
            // i.onPrice = "面议";
            // i.timeDelivery = i.timeDelivery === null;
            //     ? moment(i.timeDelivery)
            //     : moment(i.timeDelivery).format("YYYY-MM-DD");
            // console.log(i.timeDelivery);
            return i;
          });
          this.bigUrl = res.data.spuImags[0];
          this.loading = false;
        }
      });
    },

    // 星级接口
    evaluationStar(id) {
      MallAPI.evaluationStar(id).then((res) => {
        console.log(res.data);
        if (res.data === null) {
          this.isEvaluation = false;
        } else {
          this.isEvaluation = true;
          this.starValue = Number(res.data.levelStar);
          this.configFrom.indicator = res.data.indicator;
          this.configFrom.scoreList = res.data.dataList;
        }
      });
    },

    //表格更改
    handleChange(value, key, column) {
      console.log(value, key, column);
      const newData = [...this.products.skuvos];
      const target = newData[key];
      if (target) {
        if (column === "timeDelivery") {
          target[column] = moment(value).format("YYYY-MM-DD");
        } else {
          target[column] = value;
        }
        this.products.skuvos = newData;
      }
    },

    disabledDate(current) {
      // Can not select days before today and today
      return current && current < moment().endOf("day");
    },

    disabledDateTime() {
      return {
        disabledHours: () => this.range(0, 24).splice(4, 20),
        disabledMinutes: () => this.range(30, 60),
        disabledSeconds: () => [55, 56],
      };
    },

    // 正则替换小数点
    limitNumber(value) {
      if (typeof value === "string") {
        return !isNaN(Number(value)) ? value.replace(/[^0-9]/g, "") : 0;
      } else if (typeof value === "number") {
        return !isNaN(value) ? String(value).replace(/[^0-9]/g, "") : 0;
      } else {
        return 0;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.section {
  width: 100%;
  position: relative;
  background-color: #ffffff;
  margin-bottom: 16px;
  .section-content {
    width: 1200px;
    margin: 0 auto;
  }
}
.detail-section {
  min-height: 500px;
  padding: 16px 0 36px 0;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.img-container {
  width: 100%;
  height: 100%;
  background: #f2f2f2;
  img {
    width: 70%;
  }
  video {
    width: 70%;
  }
}

/deep/ .glance-container {
  width: 352px;
  height: 432px;
  margin-right: 24px;
  .big-img {
    width: 352px;
    height: 352px;
    margin-bottom: 16px;
    border: solid 1px #eeeeee;
    position: relative;
    .video-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 352px;
      height: 352px;
      z-index: 10;
      .video-icon-close {
        position: absolute;
        top: 5px;
        right: 5px;
        font-size: 20px;
        cursor: pointer;
        color: #999;
        z-index: 200;
      }
      .overlay-video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #333;
        z-index: 99;
      }

      video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 120;
      }
    }
    .video-icon {
      position: absolute;
      top: 50%;
      left: 45%;
      z-index: 100;
      font-size: 45px;
      color: rgba(255, 255, 255, 0.5);
      cursor: pointer;
    }
  }
  .small-img-container {
    position: relative;
    width: 352px;
    height: 64px;
    overflow: hidden;
    .small-wrapper {
      display: flex;
      width: 200%;
    }
    .small-img {
      width: 64px;
      height: 64px;
      margin-left: 8px;
      border: solid 1px #eeeeee;
      box-sizing: border-box;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .first-small-img {
      margin-left: 0;
    }
  }
}

.order-section {
  min-height: 200px;
}

.flexbox {
  display: flex;
  justify-content: space-between;
}
.desc-item {
  display: flex;
  margin-top: 10px;
  .desc-label {
    color: #999999;
    width: auto;
    width: 100px;
  }
  .desc-value {
    word-wrap: break-word;
    width: calc(100% - 100px);
    display: flex;
    flex-wrap: wrap;
    white-space: pre-line;
    span {
      margin-left: 5px;
    }
    table {
      border-color: #f0f0f0;
      th {
        padding: 5px 5px;
        background: #fafafa;
        width: 300px;
        text-align: center;
      }
      td {
        padding: 5px 5px;
        text-align: center;
      }
    }
  }
}

/deep/ .info-container {
  width: 824px;
  text-align: left;
}

/deep/ .ant-tabs-nav-scroll {
  text-align: initial;
}

.name-container {
  margin: 16px 0;
  text-align: left;
  .product-name {
    font-size: 18px;
    line-height: 20px;
    color: #333333;
    word-break: break-all;
  }
}

.num-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  // width:700px;
  .num-item {
    width: 150px;
    height: 50px;
    .num-value {
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      color: #333333;
    }
    .num-label {
      font-size: 14px;
      color: #999999;
      text-align: center;
    }
  }
}
.divider-vertical {
  width: 0;
  height: 36px;
  box-sizing: border-box;
  border-right: solid 0.5px #eeeeee;
  margin: 0 4px;
}
.divider {
  width: 100%;
  height: 0;
  box-sizing: border-box;
  border-bottom: solid 0.5px #eeeeee;
  margin: 16px 0;
}
.red {
  color: #e41e1e;
}
.btns {
  margin-bottom: 10px;
  width: 88px;
  height: 32px;
  display: block;
}

// 小图轮博样式
.goods-list {
  width: 64px;
  height: 64px;
  img {
    cursor: move;
    width: 100%;
    height: 100%;
  }
}
.goods-list:hover {
  img {
    border: 2px solid #ff4600;
  }
}
.swiper-container {
  width: 100%;
}
.swiper-slide {
  width: 20% !important;
  margin-right: 8px;
  text-align: center;
  font-size: 18px;
  background: #fff;
}
.swiper-button {
  width: 20px;
  height: 40px;
  background: #000;
  opacity: 0.8;
  &::after {
    content: "";
  }
}
.swiper-button-prev {
  left: 0px;
  border-radius: 0px 5px 5px 0px;
}
.swiper-button-next {
  right: 0px;
  border-radius: 5px 0px 0px 5px;
}

.simg {
  width: 150px;
  height: 150px;
}

.rz {
  margin-right: 10px;
}

/deep/ .magnifier-box img {
  width: 100%;
  height: 100%;
}

.radar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .left-wrapper {
    width: 65%;
  }
  .rigth-wrapper {
    width: 35%;
  }
}
.starN {
  margin-left: 4rem;
}

.anticon svg {
  display: inline-block;
  color: red;
}

.icon-style {
  margin-right: 0.5rem;
  padding-top: 0.2rem;
}

/deep/ .ant-tabs-bar {
  margin: 0;
}
// 商品展示视频
.goods-video-container {
  position: relative;
  height: 500px;
  background: #f7f5f5;
  video {
    width: 70%;
    height: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
}
.inventory{
  display: flex; 
  margin-top: 10px;
  .inventory-tag{
    margin-right: 24px; 
    color: #999999
  }
  .inventory-tag-item{
    border: 1px solid #ff4600;
    height: 20px;
    margin-right: 10px;
    min-width: 40px;
    background: rgba(255, 70, 0, 0.1);
    border-radius: 2px;
    text-align: center;
    color: #ff4600;
    font-size: 12px;
  }
}
</style>
