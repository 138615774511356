<template>
  <div class="order-modal-container">
    <a-modal
      :visible="orderModalVisible"
      title="下单确认"
      class="modal-style"
      @cancel="cancel"
      @ok="handleOk"
      okText="确定"
      cancelText="返回"
      :maskClosable="false"
      :confirm-loading="loading"
      :width="1067"
    >
      <div class="order mb2">请确认您的订单 :</div>
      <div style="display: flex" class="mb2">
        <div class="goods-title">商品名称 :</div>
        <div class="goods-desc">
          <div>{{ product.goodsNameCn }}</div>
          <div>{{ product.goodsNameEn }}</div>
        </div>
      </div>

      <div style="margin-top: 4rem">
        <a-table :columns="columns" :data-source="datas" :pagination="false">
          <a slot="skuImages" slot-scope="text">
            <img class="imgs" :src="text" />
          </a>
          <span slot="timeDelivery" slot-scope="text">
            {{ moment(text).format("YYYY-MM-DD") }}
          </span>
          <span slot="onPrice" slot-scope="text">
            <span class="red">¥{{ text }}</span>
          </span>
        </a-table>
      </div>
    </a-modal>
  </div>
</template>

<script>
import moment from "moment";
import { MallAPI } from "@/api/mall.js";
import { tips } from "@/utils/tips.js";
export default {
  data() {
    return {
      moment,
      columns: [
        {
          title: "图片",
          dataIndex: "skuImages",
          key: "skuImages",
          scopedSlots: { customRender: "skuImages" },
        },
        {
          title: "型号",
          dataIndex: "skuType",
          key: "skuType",
          ellipsis: true,
          scopedSlots: { customRender: "skuType" },
        },
        {
          title: "颜色",
          dataIndex: "skuColor",
          key: "skuColor",
          ellipsis: true,
          scopedSlots: { customRender: "skuColor" },
        },
        {
          title: "基础价",
          dataIndex: "onPrice",
          key: "onPrice",
          ellipsis: true,
          scopedSlots: { customRender: "onPrice" },
        },
        {
          title: "订购数量",
          dataIndex: "goodsNum",
          key: "goodsNum",
          ellipsis: true,
          scopedSlots: { customRender: "goodsNum" },
        },
        {
          title: "交货时间",
          dataIndex: "timeDelivery",
          key: "timeDelivery",
          ellipsis: true,
          scopedSlots: { customRender: "timeDelivery" },
        },
        {
          title: "交货地址",
          dataIndex: "placeDelivery",
          key: "placeDelivery",
          ellipsis: true,
          scopedSlots: { customRender: "placeDelivery" },
        },
      ],
      loading: false,
    };
  },
  props: {
    datas: {
      type: Array,
    },
    product: {
      type: Object,
    },
    orderModalVisible: {
      type: Boolean,
    },
  },
  methods: {
    handleOk() {
      console.log(this.datas[0]);
      MallAPI.buyGood(this.datas[0]).then((res) => {
        this.loading = true;
        if (res.success) {
          this.cancel();
          tips(res, "下单");
        }
        this.loading = false;
      });
    },
    // 弹出框取消按钮
    cancel() {
      this.$parent.closeModal();
    },
  },
  mounted() {
    // console.log(this.datas , this.product )
  },
};
</script>

<style lang="scss" scoped>
.red {
  color: #e41e1e;
}
.order {
  width: 576px;
  height: 17px;
  font-size: 16px;
  font-weight: 400;
  line-height: 29px;
}
.goods-title {
  width: 93px;
  height: 48px;
  font-size: 16px;
  font-weight: 400;
  color: #999999;
}
.goods-desc {
  width: 407px;
  height: 48px;
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  color: #333333;
  display: inline-block;
}
.mb2 {
  margin-bottom: 20px;
}
.imgs {
  width: 100px;
}
</style>
