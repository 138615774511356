<!-- 样品申请 -->
<template>
  <div class="sample-modal-container">
    <a-modal
      :visible="sampleVisible"
      class="modal-style"
      @cancel="cancel"
      @ok="handleOk"
      :maskClosable="false"
      :width="630"
      okText="提交"
    >
      <template slot="title">
        <span class="label star">样品申请</span>
      </template>

      <a-form-model
        ref="ruleForm"
        :model="tradeMessageDTO"
        :rules="rules"
        :label-col="labelCol"
      >
        <a-form-model-item
          label="收件人姓名"
          style="display: flex"
          prop="contacts"
        >
          <a-input
            placeholder="请输入收件人姓名"
            v-model="tradeMessageDTO.contacts"
          ></a-input>
        </a-form-model-item>

        <a-form-model-item label="手机号" style="display: flex" prop="phone">
          <a-input
            placeholder="请输入手机号"
            v-model="tradeMessageDTO.phone"
          ></a-input>
        </a-form-model-item>

        <a-form-model-item
          label="邮寄地址"
          style="display: flex"
          prop="mailAddress"
        >
          <a-textarea
            :auto-size="{ minRows: 4 }"
            v-model="tradeMessageDTO.mailAddress"
          ></a-textarea>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { SampleRule } from "@/rules/sampleApply.js";
import { orderReviewApi } from "@/api/orderReview.js";
// import { tips } from "@/utils/tips.js";
export default {
  data() {
    return {
      rules: SampleRule,
      labelCol: { span: 5 },
      tradeMessageDTO: {
        contacts: null,
        phone: null,
        mailAddress: null,
        goodsId: null,
      },
    };
  },
  props: {
    sampleVisible: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    // 弹出框取消按钮
    cancel() {
      this.$parent.closeModal();
    },
    handleOk() {
      this.submitSample();
    },
    // 样品申请提交
    submitSample() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.tradeMessageDTO.skuType = this.config.skuType;
          this.tradeMessageDTO.goodsNameCn = this.config.goodsNameCn;
          this.tradeMessageDTO.goodsId = this.config.goodsId;
          orderReviewApi.sanpleApply(this.tradeMessageDTO).then((res) => {
            if (res.success) {
              console.log(res.data);
              // tips(res, "申请");
              this.$message.success(
                "已收到您的样品申请，专属经理会在18小时内和您联系确认信息"
              );
              this.cancel();
            }
          });
        }
      });
    },
  },

  created() {
    console.log(this.config);
  },
};
</script>

<style lang="scss" scoped>
.label {
  font-size: 17px;
  color: #333;
}
.ant-input {
  width: 20rem;
}
</style>
