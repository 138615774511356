<template>
  <div class="inquiry-modal-container">
    <a-modal
      :visible="inquiryModalVisible"
      class="modal-style"
      @cancel="cancel"
      @ok="handleOk"
      :maskClosable="false"
      :width="630"
      okText="提交"
    >
      <template slot="title">
        <span class="label star">询价</span>
      </template>

      <a-textarea
        class="mb2"
        :auto-size="{ minRows: 4 }"
        :maxlength="128"
        v-model="tradeMessageDTO.suggest"
      ></a-textarea>

      <div class="label mb2">如有疑问请联系:</div>

      <div class="mb2" v-for="(item, i) in contactModeList" :key="i">
        <span class="label">{{ item.name }} : </span>
        <span class="label">{{ item.phone }}</span>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { orderReviewApi } from "@/api/orderReview.js";
import { tips } from "@/utils/tips.js";
export default {
  data() {
    return {
      // 联系方式数据Î
      contactModeList: [
        {
          name: "邱经理",
          phone: "15168182725",
        },
        {
          name: "柯经理",
          phone: "13429252121",
        },
      ],
      tradeMessageDTO: {
        contacts: null,
        phone: null,
        suggest: null,
        goodsId: null,
      },
    };
  },
  props: {
    inquiryModalVisible: {
      type: Boolean,
      default: false,
    },
    pid: {
      type: Number,
    },
    config: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    // 弹出框取消按钮
    cancel() {
      this.$parent.closeModal();
    },
    handleOk() {
      this.inquiryLeaving();
    },
    // 询价留言提交
    inquiryLeaving() {
      if (this.tradeMessageDTO.suggest === null) {
        this.$message.error("咨询与建议不能为空");
      } else {
        this.tradeMessageDTO.suggest =
          this.config.goodsNameCn +
          "-" +
          this.config.skuType +
          "：" +
          this.tradeMessageDTO.suggest;

        console.log(this.tradeMessageDTO.suggest);
        // 解决后端解析困难问题，带去选品名称和选品sku型号
        this.tradeMessageDTO.goodsNameCn = this.config.goodsNameCn;
        this.tradeMessageDTO.skuType = this.config.skuType;
        this.tradeMessageDTO.goodsId = this.config.goodsId;
        orderReviewApi.inquiryLeaving(this.tradeMessageDTO).then((res) => {
          console.log(res);
          if (res.success) {
            this.cancel();
            const detail = this.$route.query;
            this.$parent.GetDetail(detail);
            tips(res, "留言");
          }
        });
      }
    },
  },
  created() {
    console.log(this.config);
  },
};
</script>

<style lang="scss" scoped>
.label {
  font-size: 17px;
  color: #333;
}
.hint {
  font-size: 14px;
  color: #999;
}
.mb2 {
  margin-bottom: 10px;
}
.mt2 {
  margin-top: 2rem;
}
</style>
